<template>
  <div id="get-started">
    <div id="pricing" class="py-4">
      <v-container class="content">
        <h2 class="display-2 mb-10 font-weight-bold primary--text">Pricing</h2>
        <p>
          No up-front costs, a free two month trial period and a dual pricing
          structure makes this affordable for all:
        </p>
        <v-row class="d-flex py-2">
          <v-col cols="12" md="5" class="text-center text-right px-5">
            <h3 class="display-2 font-weight-bold py-4 mb-0">
              BIDS
            </h3>
            <v-divider />
            <div class="py-8">
              <p class="display-2 primary--text my-6 font-weight-bold">2%</p>
              <p class="mb-0">of annual BID income</p>
              <p class="caption grey--text text--darken-2">
                (monthly in arrears)
              </p>
            </div>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" md="2">
            <div class="d-flex full-height justify-center">
              <v-divider class="mx-auto" vertical />
            </div>
          </v-col>
          <v-col cols="12" md="5" class="text-center px-5">
            <h3 class="display-2 font-weight-bold py-4 mb-0">
              Councils
            </h3>
            <v-divider />
            <div class="py-8">
              <p class="display-2 primary--text my-6 font-weight-bold">8%</p>
              <p class="mb-0">of resident population</p>
              <p class="caption grey--text text--darken-2">
                (monthly in arrears)
              </p>
            </div>
          </v-col>
          <v-col class="text-center" cols="12">
            <v-divider />
            <p class="mt-8 mb-0">
              Business pay between <b>1%</b> and <b>1.15%</b> plus
              <b>10-12p</b> on each transaction via our payment provider.<br />
            </p>
            <p class="caption grey--text text--darken-2 mb-8">
              (There are no monthly costs or set up cost.)
            </p>
          </v-col>
        </v-row>
        <!-- <v-row class="py-2">
          <v-col cols="12" md="4" offset-md="2">
            <v-card class="full-height text-center">
              <v-alert
                colored-border
                border="top"
                color="primary"
                class="title py-4 mb-0"
              >
                BIDS Pay:
              </v-alert>
              <v-divider />
              <div class="py-8">
                <p class="display-2 my-6 text-center font-weight-bold">2%</p>
                <p class="mb-0">of annual BID income</p>
                <p class="caption grey--text text--darken-2">
                  (monthly in arrears)
                </p>
              </div>
              <v-divider />
              <p class="my-8">
                Businesses pay 1.5% of sales
              </p>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="full-height text-center">
              <v-alert
                colored-border
                border="top"
                color="primary"
                class="title py-4 mb-0"
              >
                Councils Pay:
              </v-alert>
              <v-divider />
              <div class="py-8">
                <p class="display-2 my-6 text-center font-weight-bold">8%</p>
                <p class="mb-0">of resident population</p>
                <p class="caption grey--text text--darken-2">
                  (monthly in arrears)
                </p>
              </div>
              <v-divider />
              <p class="my-8">
                Businesses pay 1.5% of sales
              </p>
            </v-card>
          </v-col>
        </v-row> -->
      </v-container>
    </div>
    <!-- <div id="discount" class="red lighten-2">
      <v-container class="content">
        <v-row>
          <v-col cols="12">
            <p class="text-center my-4 title white--text">
              Early bird discount: first five BIDs to sign up - 50% off in Year
              1
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div> -->
    <div id="next-steps" class="py-12">
      <v-container class="content">
        <h2 class="display-2 font-weight-bold mt-12 primary--text">
          Next Steps
        </h2>

        <p class="ma-10">
          <span class="display-1 font-weight-black mr-2">1.</span>Sign up – we
          have a short, simple agreement that will get you online quickly and
          allows you to trial the platform free of charge
        </p>

        <p class="ma-10">
          <span class="display-1 font-weight-black mr-2">2.</span>
          Decide on your website name – ie a new domain such as WEAREXXX or add
          a “/SHOP” page to an existing customer facing local website
        </p>

        <p class="ma-10">
          <span class="display-1 font-weight-black mr-2">3.</span>
          Circulate to local businesses via your existing newsletters and other
          digital communication channels (we have pre-drafted templates) etc
          directing them to us and we will support them uploading details of
          their business products and services to the site
        </p>

        <p class="ma-10">
          <span class="display-1 font-weight-black mr-2">4.</span>
          When a critical mass of around ten shops are online go live and
          announce the launch via existing social media etc channels and the
          local press (our experience shows it is a good story) and the
          businesses individual customer bases (in-store posters and digital)
          Sit back and let the tech do the work!
        </p>

        <p class="ma-10">
          <span class="display-1 font-weight-black mr-2">5.</span>
          We have done our best to make this as simple and painless as possible
          for BIDs and councils and will handle almost all admin for you. LONGER
          TERM – shape the future WEARELOCALS will set up a forum to which
          representatives of all users will be invited to offer suggestions and
          ideas to shape the future direction of the platform to best serve the
          needs of local independent businesses and local high street and town
          centre. No rest until traditional high street businesses have the tech
          capabilities of Amazon.
        </p>
      </v-container>
    </div>
    <v-parallax :src="require('@/assets/shape_the_future.png')">
      <div id="shape" class="py-12">
        <v-container class="content">
          <h2 class="display-2 my-10">
            LONGER TERM – shape the future
          </h2>
          <p>
            <span class="font-weight-bold">WEARELOCALS </span> will set up a
            forum to which representatives of all users will be invited to offer
            suggestions and ideas to shape the future direction of the platform
            to best serve the needs of local independent businesses and local
            high street and town centre. No rest until traditional high street
            businesses have the tech capabilities of Amazon.
          </p>
        </v-container>
      </div>
    </v-parallax>
  </div>
</template>

<script>
export default {
  name: "GetStarted",
};
</script>

<style lang="scss" scoped></style>
